import { Step, Steps } from "@allica/ui-react";
import { Dispatch, ReactNode, SetStateAction } from "react";
import { CompanySectionType } from "src/shared/company/Company.types";
import { ApplicantSection } from "src/shared/applicant/Applicant.types";
import {
  ParsedBCAConfig,
  Question,
} from "./stages/account-activity/BusinessRewardsAccountActivity.types";

export enum StageMVP {
  SIGN_UP = "sign-up",
  VERIFY = "verify",
  BUSINESS = "business",
  APPLICANT = "applicant",
  SUMMARY = "summary",
}

export enum Stage {
  SIGN_UP = "sign-up",
  VERIFY = "verify",
  BUSINESS = "business",
  APPLICANT = "applicant",
  DEPOSIT = "deposit",
  ACCOUNT = "account",
  DOCUMENT = "document",
  SUMMARY = "summary",
}

export enum SubmittedStages {
  COMPLETED = "COMPLETED",
  READY_TO_REVIEW = "READY_TO_REVIEW",
  SPLITTING = "SPLITTING",
  RISK_CHECK_IN_PROGRESS = "RISK_CHECK_IN_PROGRESS",
  PROCESSING_ERROR = "PROCESSING_ERROR",
}

export enum ApplicationStatuses {
  STARTED = "STARTED",
  COMPLETED = "COMPLETED",
  REVIEW = "REVIEW",
  FAIL = "FAIL",
}

export type AlertStage = Stage.APPLICANT;

type ApplicationSteps = keyof typeof Stage;
export type CurrentApplicationStage = ApplicationSteps | keyof typeof SubmittedStages;
export type ApplicationStatusType = keyof typeof ApplicationStatuses;

export type UpdateStepperConfig = (
  stages: { stage: Stage; value: Partial<Step> }[],
  disableCompleteOverride?: boolean,
) => void;

export interface ApplicationSections {
  signUpSection: {
    emailAddress: string;
    phoneNumber: string;
    password?: string;
    domain: "BRA";
  };
  verifySection?: {
    noOfSecurityCodeSent: number;
  };
  companySection: CompanySectionType;
  applicantSection: ApplicantSection | null;
  // temporarily optional START (FeatureFlag.BRA_PHASE_TWO)
  depositSection?: {
    depositAmount: string;
    depositDuration: string;
  };
  accountSection?: {
    purposeOfAccount: string[];
    monthlyPaymentsIn: string;
    sourceOfFunds: string[];
  };
  documentSection?: {
    terms: boolean;
    email?: boolean;
    sms?: boolean;
    phone?: boolean;
    mail?: boolean;
  };
  // temporarily optional END (FeatureFlag.BRA_PHASE_TWO)
  summarySection: {
    detailsVerified: boolean;
  };
  outcome: {
    success: boolean;
  };
}

export interface BusinessRewardsApiType extends Omit<ApplicationSections, "accountSection"> {
  currentApplicationStage: CurrentApplicationStage | null;
  cobApplicationId: string;
  accountSection: {
    fullAccountQuestionnaireDetails: {
      questions: Question[];
    };
  };
}

export interface BusinessRewardsDataTypes {
  applicationID: string;
  currentApplicationStage: CurrentApplicationStage | null;
  cobApplicationId: string;
  businessRewardsApplicationSections: ApplicationSections;
  questions: ParsedBCAConfig | null;
}

export interface BusinessRewardsContextType {
  businessRewardsData: BusinessRewardsDataTypes;
  setBusinessRewardsData: Dispatch<React.SetStateAction<BusinessRewardsDataTypes>>;
  currentStage: Stage | null;
  setCurrentStage: Dispatch<SetStateAction<Stage | null>>;
  stepperConfig: Steps<Stage>;
  updateStepperConfig: UpdateStepperConfig;
  showGenericError: boolean;
  setShowGenericError: Dispatch<React.SetStateAction<boolean>>;
  alertStages: AlertStage[];
  setAlertStages: Dispatch<React.SetStateAction<AlertStage[]>>;
  isBusinessRewardsDataLoading: boolean;
}

export type BusinessRewardsContextProviderType = {
  children?: ReactNode;
  applicationID: string;
  applicationIDStatus: { loading: boolean; success: boolean };
};
